var placeholderNames = {
    "jumperName": "Namn",
    "jumperLicenseNumber": "Licensnummer",
    "jumperLicense": "Licens",
    "jumperCompetences": "Behörigheter",
    "location": "Plats",
    "jumperClub": "Klubb",
    "club": "Klubb",
    "hlName": "Namn",
    "hlLicense": "Licensnummer",
    "jumperExperienceYear": "1",
    "jumperExperienceMonth": "6",
    "totalJumps": "3",
    "jumpsLastThreeMonths": "5",
    "jumpsOfType": "5",
    "jumpsWithSameGear": "7",
    "jumperWeight": "83",
    "riggType": "Javector J1K-304",
    "canopy": "Performance Aerosports Pilot 7",
    "reserve": "Reserv",
    "aad": "BoC",
    "mainPacker": "Namn",
    "reservePacker": "Namn och k:nr",
    "reserveValidThrough": "Klicka för att välja",
    "aadValidThrough": "Klicka för att välja",
    "aadBatteryChange": "Klicka för att välja",
    "rsl": "RSL",
    "jumptype": "Händelsen var inte under hoppning",
    "malfunction": "Inget reservdrag",
    "malfunctionReason": "",
    "injury": "Inga skador",
    "propertyDamage": "Ingen egendomsskada",
    "injuryMore": "",
    "landingPlace": "Inget att anmärka",
    "landingStyle": "Inget att anmärka",
    "canopyStyle": "Inget att anmärka",
    "groundWind": "Markvind",
    "gustWind": "Byvind",
    "wind1000m": "Höjdvind",
    "wind4000m": "Höjdvind",
    "clouds": "Klart",
    "weather": "Inget att anmärka"
}


export default placeholderNames;