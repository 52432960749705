import React from 'react';

import Navigation from './Navigation'
import { ReportSection } from './MyReports';
import {Helmet} from "react-helmet";

import API2 from './API';


class PublishedReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = { publishedReports: [] };
    this.logout = props.logout;
  }

  componentDidMount() {
    API2.publishedReports().then(d => {
      this.setState({ publishedReports: d });
    });
  }

  render() {
    return (
      <div>
        <Navigation logout={this.logout} />
        <Helmet>
          <title>Publicerat</title>
          <link rel="icon" href="/favicon.png" />
        </Helmet>
        <div className="container" style={{ backgroundColor: "#F3F3F3", borderRadius: "5px", padding: "25px" }}>
          {ReportSection("Publicerade rapporter", this.state.publishedReports, false)}
        </div>
      </div>
    );
  }
}

export default PublishedReports;