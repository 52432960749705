
function FormGroup(props) {
    return (<div className={"form-group form-group-group"} style={{ marginBottom: "10px" }}>
      <h2>{props.title}</h2>
      {props.children}
    </div>
    )
  }
  
  function FormRow(props) {
    var extraClass = "";
    if (props.extraClass != null) {
      extraClass = props.extraClass;
    }
    if (props.children != null) {
      return (<div className={"form-row"}>
        {props.children.filter(e => e != null).map((c, i) =>
        (<div key={i} className={"col " + extraClass}>
          {c}
        </div>)
        )}
      </div>);
    } else {
      console.log("No children?!")
      return (<div className={"form-row"}>
        {props.children}
      </div>);
    }
  }

export default {FormGroup, FormRow}