import React from 'react';

import Navigation from './Navigation'
import API2 from './API';
//import { Helmet } from 'react-helmet-async';
import {Helmet} from "react-helmet";

import { ReportSection } from './MyReports';


class TodoReports extends React.Component {

  constructor(props) {
    super(props);
    this.state = { myReports: [] };
    this.logout = props.logout;
  }

  componentDidMount() {
    API2.todoReports().then(d => {
      this.setState({ myReports: d });
    });
  }

  render() {
    var reps = this.state.myReports;
    return (
      <div>
        <Helmet>
          <title>Granska</title>
          <link rel="icon" href="/favicon.png" />
        </Helmet>
        <Navigation logout={this.logout} />
        <div className="container" style={{ backgroundColor: "#F3F3F3", borderRadius: "5px", padding: "25px" }}>
          <h2>Rapporter</h2>
          {ReportSection("Att granska", reps, true)}
        </div>
      </div>
    );
  }
}

export default TodoReports;