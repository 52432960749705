import React from "react";
import { PieChart, Pie, Cell } from "recharts";

import ContrastColor from 'contrast-color';

const RADIAN = Math.PI / 180;

var contrastColor = new ContrastColor();

export default function PiChartFunc(data) {
  const renderCustomizedLabel = (
    {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      name,
      payload
    }
  ) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={contrastColor.contrastColor({ bgColor: payload.color })}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${name}: ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  console.log("Pi chart data: ", data);

  if(data.length == 0){
    data = [{name: "Inga", value: 1, color: "#a9a9a9"}];
  }

  return (
    <PieChart width={1000} height={600}>
      <Pie
        data={data}
        cx={300}
        cy={300}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={250}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
    </PieChart>
  );
}