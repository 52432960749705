import React from 'react';

import Navigation from './Navigation'

//import { Helmet } from 'react-helmet-async';
import { Helmet } from "react-helmet";

import { getDecoded } from './AuthWrapper';

import Pl from './reports/ReportPlacing';

import Sv from './reports/SelectionValues';


const Start = (props) => {
  const role = getDecoded().role;

  var todoReports = (<></>);
  if (role !== "ANYONE") {
    todoReports = MenyVal2("Rapporter att hantera", "#EBE8FC", "/toHandle", "Visa");
  }
  var grey = "#F3F3F3";
  return (
    <div>
      <Navigation logout={props.logout} />
      <div className="container" style={{ backgroundColor: grey, borderRadius: "5px", padding: "25px" }}>
        <Pl.FormRow>
          {MenyVal2("Händelseregistrering", Sv.SelectionFancy("type", "BLUE").color/*"#e8f4f8"*/, "/blue", "Skapa")}
          {MenyVal2("Reservdragsrapport", Sv.SelectionFancy("type", "YELLOW").color/*"#fdfdd0"*/, "/yellow", "Skapa")}
        </Pl.FormRow>
        <Pl.FormRow>
          {MenyVal2("Fallskärmsrapport", Sv.SelectionFancy("type", "RED").color, "/red", "Skapa")}
          {MenyVal2("Mina rapporter", grey, "/myReports", "Visa")}
        </Pl.FormRow>
        {todoReports}
        {MenyVal2("Publicerade rapporter", grey, "/published", "Visa")}
        {MenyVal2("Se diagram", "#e8f7d9", "/charts", "Visa")}
      </div>
    </div >
  );
};

function MenyVal2(title, color, link, subtext) {
  return (<a key={title} href={"#" + link} style={{ color: "black" }}>
    <Helmet>
      <title>Rapporter meny</title>
      <link rel="icon" href="/favicon.png" />
    </Helmet>
    <br />
    <div className="container" style={{
      backgroundColor: color, borderRadius: "5px",
      padding: "15px",
      borderColor: "black",
      border: "2px solid"
    }}>
      <h2 style={{ textAlign: "center" }}>{title}</h2>
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <p>{subtext}</p>
          </div>
        </div>
      </div>
    </div>
  </a>)
}

export default Start;