
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import React from 'react';

function StackLineFunc(data) {
  return (
    <AreaChart
      width={1000}
      height={400}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Area
        type="monotone"
        dataKey="blue"
        stackId="1"
        stroke="#add8e6"
        fill="#e8f4f8"
      />
      <Area
        type="monotone"
        dataKey="yellow"
        stackId="1"
        stroke="#dfdf64"
        fill="#ececa3"
      />
      <Area
        type="monotone"
        dataKey="red"
        stackId="1"
        stroke="#E6BCAD"
        fill="#f8ece8"
      />
    </AreaChart>
  );
}

export default StackLineFunc;