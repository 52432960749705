import React from 'react';
import { getAuth } from './AuthConsts';
import { jwtDecode } from "jwt-decode";
import {Helmet} from "react-helmet";


class AuthWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userName: "", password: "", children: props.children };

    props.logout.logoutFunc = () => this.logout();
    //const jwt = getDecoded();

    //this.state.isAuthed = false;


    this.expireCheckFunc(false);
  }

  expireCheckFunc(setState) {
    const jwt = getDecoded();

    this.state.isAuthed = false;

    if (jwt.exp != null) {
      var millisLeft = (jwt.exp * 1000) - Date.now();
      if (millisLeft <= 0) {
        if (setState) {
          this.setState({ isAuthed: false });
        } else {
          this.state.isAuthed = false;
        }
      } else { // all good
        if (setState) {
          this.setState({ isAuthed: true });
        } else {
          this.state.isAuthed = true;
        }

        var o = this;
        setTimeout(() => o.expireCheckFunc(true), millisLeft);
      }
    }
  }


  jumperLicenseChange(e) {
    this.setState({ userName: e.target.value });
  }

  passwordChange(e) {
    this.setState({ password: e.target.value });
  }

  render() {

    if (this.state.isAuthed) {
      return (
        <div>
          {this.state.children}
        </div>
      );
    } else {
      return (
        <div>
          <Helmet>
            <title>Rapportering</title>
            <link rel="icon" href="/favicon.png" />
          </Helmet>
          <br />
          <div className="container" style={{ backgroundColor: "#e8f4f8", borderRadius: "5px", padding: "25px" }}>
            <form id="form" className="needs-validation" >
              <h2>Login</h2>
              <div className="form-group">
                <label htmlFor="jumperLicensenumber">Licensnummer</label>
                <input type="text" className="form-control" name="username" placeholder="00001" required onChange={(e) => this.jumperLicenseChange(e)} />
              </div>
              <div className="form-group">
                <label htmlFor="jumperLicense">Lösenord</label>
                <input type="password" className="form-control" name="password" placeholder="****" required onChange={(e) => this.passwordChange(e)} />
              </div>
              <div className="text-center">
                {this.LoginInfo()}
                <button type="submit" className="btn btn-primary waves-effect waves-light " id="btnSubmit" onClick={(e) => this.login(e)}>Logga
                  in</button>
              </div>
            </form>
          </div>
        </div>);
    }
  }

  LoginInfo() {
    if (this.state.errorMessage != null) {
      return (<p style={{ color: "red" }}>Login failed!</p>);
    }
  }

  login(e) {
    e.preventDefault();
    this.setState({ haveTried: true });
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userName: this.state.userName, password: this.state.password })
    };
    fetch('/login', requestOptions)
      .then(response => response.json()
      ).then((data) => {
        if (!data.success) {
          this.setState({ isAuthed: false });
          return Promise.reject("Response was not success");
        } else {
          this.setState({ isAuthed: true });
        }
      }).catch(error => {
        this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
        this.setState({ isAuthed: false });
      });
  }

  logout() {
    console.log("logout");

    this.setState({ haveTried: false });
    this.setState({ isAuthed: false });

    const requestOptions = {
      method: 'POST',
    };
    fetch('/logout', requestOptions)
      .catch(error => {
        console.error('There was an error!', error);
      });
  }
}



export const getDecoded = () => {
  const coded = getAuth();
  var decoded = { sub: 'Not logged in' };
  if (coded != null) {
    decoded = jwtDecode(getAuth());
  }
  return decoded;
}

export default AuthWrapper;
