import React from 'react';
import ReactDOM from 'react-dom/client';

import { HashRouter, Route, Routes } from 'react-router-dom';

import Start from './Start';
import Report from './reports/ReportPage';

import { MyReports } from './MyReports';
import PublishedReports from './PublishedReports';
import  TodoReports from './TodoReports';
import  Charts from './Charts';

import AuthWrapper from './AuthWrapper';

//import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById("root"));

const logoutPasser = { logoutFunc: null };

root.render(
  <div className="root">
  {/** <React.StrictMode> */}
   {/** <HelmetProvider> **/}
      <AuthWrapper logout={logoutPasser}>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Start logout={logoutPasser} />} />
            <Route path="/red" element={<Report.EmptyReport reportType={"RED"} logout={logoutPasser} />} />
            <Route path="/yellow" element={<Report.EmptyReport reportType={"YELLOW"} logout={logoutPasser} />} />
            <Route path="/blue" element={<Report.EmptyReport reportType={"BLUE"} logout={logoutPasser} />} />
            <Route path="/report/:token" element={<Report.SpecificReport logout={logoutPasser} />} />
            <Route path="/myReports" element={<MyReports logout={logoutPasser} />} />
            <Route path="/toHandle" element={<TodoReports logout={logoutPasser} />} />
            <Route path="/published" element={<PublishedReports logout={logoutPasser} />} />
            <Route path="/Charts" element={<Charts logout={logoutPasser} />} />
          </Routes>
        </HashRouter>
      </AuthWrapper>
    {/**</HelmetProvider> **/}
  {/** </React.StrictMode> */}
  </div>
);
