import React from 'react';

import '../style.css';
import '../static/bootstrap.min.css'

import 'bootstrap/dist/css/bootstrap.min.css';

import El from './ReportElements';

import Pl from './ReportPlacing';

function Datum(r, editable, issues) {
  return (<Pl.FormGroup title={"Datum"}>
    {El.date(r, "eventDate", "Datum", editable, issues?.includes("eventDate"))}
  </Pl.FormGroup>);
}

function Person(r, editable, issues) {
  return (<Pl.FormGroup title={"Gällande person"}>
    {El.stringValue(r, "jumperLicenseNumber", "Licensnummer", editable)}
    {El.stringValue(r, "jumperName", "Namn", editable)}
    <Pl.FormRow>
      {El.selectValue(r, "jumperLicense", "Jumper license", editable)}
      {El.selectMany(r, "jumperCompetences", "Behörigheter", editable)}
    </Pl.FormRow>
    <Pl.FormRow>
      {El.selectValueNiceName(r, "club", "I vilken klubbs regi", editable, issues?.includes("club"))}
      {El.selectValueNiceName(r, "jumperClub", "Klubbtillhörighet", editable, issues?.includes("jumperClub"))}
    </Pl.FormRow>
    {El.stringValue(r, "location", "Plats för händelse", editable)}
    {El.number(r, "jumperWeight", "Hopparens kroppsvikt (kg)", editable)}
  </Pl.FormGroup>);
}

function Erfarenhet(r, editable) {
  return (<Pl.FormGroup title={"Erfarenhetsnivå"}>
    <Pl.FormRow>
      {El.number(r, "jumperExperienceYear", "År i sporten", editable)}
      {El.number(r, "jumperExperienceMonth", "Månader i sporten", editable)}
    </Pl.FormRow>
    <Pl.FormRow>
      {El.number(r, "totalJumps", "Totalt antal hopp", editable)}
      {El.number(r, "jumpsLastThreeMonths", "Antal hopp senaste tre månaderna", editable)}
    </Pl.FormRow>
    <Pl.FormRow>
      {El.number(r, "jumpsOfType", "Antal hopp av den typ som utfördes", editable)}
      {El.number(r, "jumpsWithSameGear", "Antal hopp med aktuell utrustning", editable)}
    </Pl.FormRow>
  </Pl.FormGroup>);
}

function Utrustning(r, editable) {
  return (<Pl.FormGroup title={"Utrustning"}>
    {El.stringValue(r, "riggType", "Typ av rigg", editable)}
    {El.stringValue(r, "canopy", "Typ av huvudfallskärm", editable)}
    {El.stringValue(r, "reserve", "Typ av reservfallskärm", editable)}
    {El.stringValue(r, "aad", "Utlösningssystem samt placering", editable)}
    {El.stringValue(r, "mainPacker", "Namn på packare av huvudfallskärm", editable)}
    {El.stringValue(r, "reservePacker", "Namn och K:nr på packare av reservfallskärm", editable)}
    <Pl.FormRow>
      {El.date(r, "reserveValidThrough", "Reservfallskärmen giltig t o m", editable)}
      {El.date(r, "aadValidThrough", "Räddningsutlösaren giltig t.o.m", editable)}
    </Pl.FormRow>
    <Pl.FormRow>
      {El.date(r, "aadValidThrough", "Datum för utbytt batteri och/eller patron", editable)}
      {El.selectValueNiceName(r, "rsl", "Utrustad med RSL", editable)}
    </Pl.FormRow>
  </Pl.FormGroup>);
}

function TypAvHopp(r, editable) {
  return (<Pl.FormGroup title={"Under vilken typ av hoppning det hände"}>
    {El.selectValueNiceName(r, "jumptype", "Typ av hopp", editable)}
  </Pl.FormGroup>);
}

function FaktaOmHandelsen(r, editable) {
  return (<Pl.FormGroup title={"Fakta om händelsen"}>
    {El.selectManyGrouped(r, "malfunction", "Orsak reservdrag", editable)}
    {El.textValue(r, "malfunctionReason", "Beskriv den uppfattade bedömda orsaken till störningen/felfunktionen", 2, editable)}
  </Pl.FormGroup>);
}

function FaktaOmOlycka(r, editable) {
  return (<Pl.FormGroup title={"Fakta om olyckan"}>
    {El.selectManyGrouped(r, "injury", "Personskada", editable)}
    {El.selectValueNiceName(r, "propertyDamage", "Egendomsskada", editable)}
    {El.textValue(r, "injuryMore", "Faktorer som kan ha orsakat/bidragit till skadans uppkomst (om nedanstående alternativ inte beskriver vad som hänt, komplettera med text här)", 2, editable)}
    <Pl.FormRow>
      {El.selectManyNiceName(r, "landingPlace", "Landningsplats", editable, true)}
      {El.selectManyNiceName(r, "canopyStyle", "Fallskärmsteknik", editable, true)}
    </Pl.FormRow>
    {El.selectManyNiceName(r, "landingStyle", "Landningsteknik", editable, true)}
  </Pl.FormGroup>);
}

function Vader(r, editable) {
  return (<Pl.FormGroup title={"Väderförhållanden"}>
    <Pl.FormRow>
      {El.stringValue(r, "groundWind", "Vind (m/s)", editable)}
      {El.stringValue(r, "gustWind", "Byvind (m/s)", editable)}
    </Pl.FormRow>
    <Pl.FormRow>
      {El.stringValue(r, "wind1000m", "Höjdvind på 1000m (m/s)", editable)}
      {El.stringValue(r, "wind4000m", "Höjdvind på 4000m (m/s)", editable)}
    </Pl.FormRow>
    {El.selectManyNiceName(r, "clouds", "Moln", editable, true)}
    {El.selectManyNiceName(r, "weather", "Övriga väderfenomen", editable, true)}
  </Pl.FormGroup>);
}

function Forlopp(r, editable, issues) {
  return (<Pl.FormGroup title="Redogörelse för händelseförlopp">
    {El.textValue(r, "eventDescription", "Typ av hopp, orsak, vidtagna åtgärder, vittnen etc", 4, editable, issues?.includes("eventDescription"))}
  </Pl.FormGroup>);
}

function Hoppledare(r, editable) {
  return (<Pl.FormGroup title="Hoppledarens kommentarer">
    {El.textValue(r, "hlComment", "Orsak, ev. vidtagna åtg. etc.", 4, editable)}
    <Pl.FormRow>
      {El.stringValue(r, "hlName", "Hoppledarens namn (ej obligatoriskt)", editable)}
      {El.stringValue(r, "hlLicense", "Hoppledarens licensnummer (ej obligatoriskt)", editable)}
    </Pl.FormRow>
  </Pl.FormGroup>);
}
function Ci(r, editable, visible) {
  return (
    <VisibleFor notStates={["created"]} report={r} key="ciComment">
      <Pl.FormGroup title="Chefsinstruktörens sammanfattning">
        {El.textValue(r, "ciComment", "Orsak, vidtagna åtgärder etc", 4, editable)}
      </Pl.FormGroup>
    </VisibleFor>);
}

function Ri(r, editable) {
  return (
    <VisibleFor notStates={["created", "atCi"]} report={r} key="riComment">
      <Pl.FormGroup title="RI kommentar">
        {El.textValue(r, "riComment", "Synpunkter", 4, editable)}
      </Pl.FormGroup>
    </VisibleFor>);
}

function VisibleFor(props) {
  if (props.notStates != null && props.notStates.includes(props.report.state)) {
    return (null)
  }
  if (props.notStates == null && props.state != null && props.state !== props.report.state) {
    return (null)
  }
  return (<div>
    {props.children}
  </div>);

}

var exports = { Datum, Person, Erfarenhet, Utrustning, TypAvHopp, FaktaOmHandelsen, FaktaOmOlycka, Vader, Forlopp, Hoppledare, Ci, Ri };

export default exports;