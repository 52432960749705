import React from 'react';

import API2 from '../API';

import Report from './Report';
import Buttons from './ReportButtons';

import Navigation from '../Navigation';
//import { Helmet } from 'react-helmet-async';
import {Helmet} from "react-helmet";


import { useParams } from "react-router-dom";

import sv from './SelectionValues';
import { getDecoded } from '../AuthWrapper';

import Sv from './SelectionValues';



class SpecificReportIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = { report: null, logout: props.logout };
    this.token = props.params.token;
  }

  componentDidMount() {
    API2.loadReport(this.token)
      .then((report) => {
        this.setState({ report: report });
      })
      .catch((e) => {
        e.then(m => {
          console.log("Got error message: ", m);
          this.setState({ error: m.message });
        })
      });
  }

  render() {
    if (this.state.report != null) {
      return OneReport(this.state.report, this.state.logout, this.state.errorMessage, this.updateCallBack(), this.state.issues);
    } else if (this.state.error != null) {
      return (<div>
        <Navigation logout={this.state.logout} />
        <div className={"container"} style={{ backgroundColor: "#DDDDDD", borderRadius: '5px', padding: '25px' }}>
          <p>Kan inte see: {this.state.error}</p>
        </div>
      </div>);
    }
    else {
      console.log("Reportstate: ", this.state);
      return (<div>
        <Navigation logout={this.state.logout} />
        <p>Not anything to see here</p>
      </div>);
    }
  }

  updateCallBack() {
    var thiz = this;
    return (r) => thiz.setState(r);
  }
}

class EmptyReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = { report: null, logout: props.logout };
  }

  componentDidMount() {
  }

  render() {
    return OneReport({ type: this.props.reportType, state: "created", createdBy: getDecoded().sub }, this.state.logout, null, this.updateCallBack(), this.state.issues);
  }

  updateCallBack() {
    var thiz = this;
    return (r) => thiz.setState(r);
  }
}


function OneReport(report, logout, errorMessage, stateBack, issues) {
  return (
    <div>
      <Navigation logout={logout} />
      <DoReport report={report} reportType={report.type} errorMessage={errorMessage} error stateBack={stateBack} issues={issues} />
    </div>
  );
}

function DoReport(props) {
  var reportType = props.report.reportType;
  var r = null;
  if (props.report != null) {
    r = props.report;
    reportType = r.type;
  } else {
    r = {};
  }
  var jwtBody = getDecoded();

  var editableForUser = r.state === "created";

  if (reportType === 'RED') {
    return RedReport(r, props.errorMessage, props.stateBack, editableForUser, jwtBody, props.issues);
  }
  if (reportType === 'BLUE') {
    return BlueReport(r, props.errorMessage, props.stateBack, editableForUser, jwtBody, props.issues);
  }
  if (reportType === 'YELLOW') {
    return YellowReport(r, props.errorMessage, props.stateBack, editableForUser, jwtBody, props.issues);
  }
  console.log("Did no report");
  return (
    <div className={"container"} style={{ backgroundColor: "EFEFEF", borderRadius: '5px', padding: '25px' }}>
      <p>Unable to load or view report</p>
    </div>);
}


function BlueReport(r, errorMessage, stateBack, editableForUser, jwtBody, issues) {
  return (<div className={"container"} style={{ backgroundColor: Sv.SelectionFancy("type", "BLUE").color, borderRadius: '5px', padding: '25px' }}>
    <Helmet>
      <title>Blå rapport</title>
      <link rel="icon" href="/favicon-blue.png" />
    </Helmet>
    <form id={"form"} className={"needs-validation"}>
      {State(r)}
      <h2>Händelseregistrering</h2>
      <p>
        Det här är en HÄNDELSEREGISTRERING. Den fylls i om det inträffat en händelse som kan användas för lärande och utveckling av enskilda såväl verksamhet, det kan vara positivt, negativt, neutralt. Om det finns lärande men varken renderade i reservdrag eller personskada, skrivs denna blankett.
      </p>
      {Report.Datum(r, editableForUser, issues)}
      {Report.Person(r, editableForUser, issues)}
      {Report.Erfarenhet(r, editableForUser)}
      {Report.TypAvHopp(r, editableForUser)}
      {Report.Vader(r, editableForUser)}
      {Report.Forlopp(r, editableForUser, issues)}
      {Report.Hoppledare(r, editableForUser)}
      {CiRiPart(r, jwtBody)}
      {Buttons(r, errorMessage, stateBack, jwtBody, buttonIssues(issues))}
    </form>
  </div>);
}

function buttonIssues(issues) {

  return issues != null && issues.length !== 0;
}

function YellowReport(r, errorMessage, stateBack, editableForUser, jwtBody, issues) {
  
  return (<div className={"container"} style={{ backgroundColor: Sv.SelectionFancy("type", "YELLOW").color, borderRadius: '5px', padding: '25px' }}>
    <Helmet>
      <title>Gul rapport</title>
      <link rel="icon" href="/favicon-yellow.png" />
    </Helmet>
    <form id={"form"} className={"needs-validation"}>
      {State(r)}
      <h1>Gul rapport</h1>
      <p>Rapportering av reservdrag</p>
      {Report.Datum(r, editableForUser, issues)}
      {Report.Person(r, editableForUser, issues)}
      {Report.Erfarenhet(r, editableForUser)}
      {Report.Utrustning(r, editableForUser)}
      {Report.TypAvHopp(r, editableForUser)}
      {Report.FaktaOmHandelsen(r, editableForUser)}
      {Report.Vader(r, editableForUser)}
      {Report.Forlopp(r, editableForUser, issues)}
      {CiRiPart(r, jwtBody)}
      {Buttons(r, errorMessage, stateBack, jwtBody, buttonIssues(issues))}
    </form>
  </div>);
}

function RedReport(r, errorMessage, stateBack, editableForUser, jwtBody, issues) {
  return (<div className={"container"} style={{ backgroundColor: Sv.SelectionFancy("type", "RED").color, borderRadius: '5px', padding: '25px' }}>
    <Helmet>
      <title>Röd rapport</title>
      <link rel="icon" href="/favicon-red.png" />
    </Helmet>
    <form id={"form"} className={"needs-validation"}>
      {State(r)}
      <h1>Fallskärmsrapport</h1>
      <p>Tillbud incident</p>
      {Report.Datum(r, editableForUser, issues)}
      {Report.Person(r, editableForUser, issues)}
      {Report.Erfarenhet(r, editableForUser)}
      {Report.Utrustning(r, editableForUser)}
      {Report.TypAvHopp(r, editableForUser)}
      {Report.FaktaOmOlycka(r, editableForUser)}
      {Report.Vader(r, editableForUser)}
      {Report.Forlopp(r, editableForUser, issues)}
      {CiRiPart(r, jwtBody)}
      {Buttons(r, errorMessage, stateBack, jwtBody, buttonIssues(issues))}
    </form>
  </div>);
}

function CiRiPart(r, jwtBody) {
  return (<div>
    {Report.Ci(r, r.state === "atCi" && r.club === jwtBody.ciClub) /* limited when visible */}
    {Report.Ri(r, r.state === "atRi" && jwtBody.role === "RI") /* limited when visible */}
  </div>);
}

function SpecificReport(props) {
  return (
    <SpecificReportIn {...props} params={useParams()} />
  );
}


function State(r) {
  var clazz = { border: "solid", padding: "5px", borderRadius: "3px" };
  if (r.state != null) {
    return <p className="float-right h5" style={clazz}>{sv.SelectionValues.state.filter(s => s.name === r.state)[0].niceName}</p>
  }
  return <p className="float-right h5" style={clazz}>Nyskapad</p>;
}

var exports = { OneReport, DoReport, SpecificReport, EmptyReport, State };

export default exports;
