
async function loadReport(token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    }
  };
  return fetch('/api/report/' + token, requestOptions)
    .then(response => 
      {
        if(response.ok){
          return response.json()
        }
        return Promise.reject(response.json());
      }
    );
}

async function myReports() {
  const requestOptions = {
    method: 'GET',
  };
  return fetch('/api/myReports', requestOptions)
    .then(response => response.json()
    ).then((data) => {
      return data;
    })
    .catch(error => {
      console.error('There was an error!', error);
      return ":(";
    });
}

async function todoReports() {
  const requestOptions = {
    method: 'GET',
  };
  return fetch('/api/toHandle', requestOptions)
    .then(response => response.json()
    ).then((data) => {
      return data;
    })
    .catch(error => {
      console.error('There was an error!', error);
      return ":(";
    });
}

async function publishedReports() {
  const requestOptions = {
    method: 'GET',
  };
  return fetch('/api/publishedReports', requestOptions)
    .then(response => response.json()
    ).then((data) => {
      return data;
    })
    .catch(error => {
      console.error('There was an error!', error);
      return ":(";
    });
}

async function createReport(report) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(report),
  };
  return fetch('/api/report', requestOptions)
    .then(response => response.json()
    ).then((data) => {
      return data;
    })
    .catch(error => {
      console.error('There was an error!', error);
      return ":(";
    });
}

async function createReportToCi(report) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(report),
  };
  return fetch('/api/reportToCi', requestOptions)
    .then(response => response.json()
    ).then((data) => {
      return data;
    })
    .catch(error => {
      console.error('There was an error!', error);
      return ":(";
    });
}

async function updateReport(report, newState = "") {
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify(report),
  };
  return fetch('/api/report/' + report.token + newState, requestOptions)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response.json());
  });
}


async function deleteReport(report) {
  const requestOptions = {
    method: 'DELETE',
  };
  return fetch('/api/report/' + report.token , requestOptions)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response.json());
  });
}

async function stats(year){
  const requestOptions = {
    method: 'GET',
  };
  return fetch('/api/stats/data?' + new URLSearchParams({year: year}), requestOptions)
    .then(response => response.json()
    ).then((data) => {
      return data;
    })
    .catch(error => {
      console.error('There was an error!', error);
      return ":(";
    });
}

async function statYears(){
  const requestOptions = {
    method: 'GET',
  };
  return fetch('/api/stats/years', requestOptions)
    .then(response => response.json()
    ).catch(error => {
      console.error('There was an error!', error);
      return ":(";
    });
}


export default { loadReport, myReports, todoReports, publishedReports, createReport, createReportToCi, updateReport, deleteReport, stats, statYears };