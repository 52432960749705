
import Select from 'react-select'
import Placeholders from './Placeholders';
import sv from './SelectionValues';

function simpleChange(r, id) {
  return (e) => r[id] = e.target.value;
}

function ElementWrap(props) {
  return (<div className={"form-group"}>
    {props.children}
  </div>);
}

function getPlaceHolder(id, editable) {
  return placeDefault(Placeholders[id], editable);
}

function placeDefault(value, editable) {
  if (editable) {
    return value;
  }
  return "";
}

function stringValue(report, id, name, editable) {
  if (Placeholders[id] == null) {
    console.log("Couldn't find the placeholder for: " + id);
  } else {
  }
  return (<ElementWrap>
    <label htmlFor={id}>{name}</label>
    <input type={"text"} className={"form-control"} id={id} onChange={simpleChange(report, id)} placeholder={getPlaceHolder(id, editable)} readOnly={!editable} defaultValue={report[id]} />
  </ElementWrap>);
}

function nonEdit(id, name, value, placeholder) {
  return (<ElementWrap>
    <label htmlFor={id}>{name}</label>
    <input type={"text"} className={"form-control"} id={id} readOnly={true} defaultValue={value} placeholder={placeholder} />
  </ElementWrap>);
}

function number(report, id, name, editable) {
  return (<ElementWrap>
    <label htmlFor={id}>{name}</label>
    <input type="number" className="form-control" id={id} onChange={simpleChange(report, id)} placeholder={placeDefault("1", editable)} readOnly={!editable} defaultValue={report[id]} />
  </ElementWrap>);
}

function textValue(report, id, name, rows, editable, issue) {
  return (<ElementWrap>
    <label htmlFor={id}>{name}</label>
    {errorPrint(issue)}
    <textarea className="form-control" id={id} onChange={simpleChange(report, id)} rows={rows} readOnly={!editable} defaultValue={report[id]} />
  </ElementWrap>);
}

function date(report, id, name, editable, issue) {
  return (<ElementWrap>
    <label htmlFor={id}>{name}</label>
    {errorPrint(issue)}
    <input type="date" className={"form-control" + (issue ? " :invalid" : "")} id={id} onChange={simpleChange(report, id)} readOnly={!editable} defaultValue={report[id]} />
  </ElementWrap>);
}

function selectBase(report, id, name, transform, editable, issue) {
  let options = sv.SelectionValues[id].map(v => transform(v));
  let alreadySelected = null;
  if (report[id] != null) {
    alreadySelected = options.filter(e => e.value === report[id]);
  }
  if (editable) {
    return (<ElementWrap>
      <label htmlFor={id}>{name}</label>
      {errorPrint(issue)}
      <Select placeholder={Placeholders[id]} defaultValue={alreadySelected} onChange={e => { report[id] = e.value; }} options={options} />
    </ElementWrap>)
  }
  return nonEdit(id, name, alreadySelected == null || alreadySelected.length === 0 ? "" : alreadySelected[0].label)
}

function selectValue(report, id, name, editable) {
  return selectBase(report, id, name, v => ({ value: v, label: v }), editable);
}

function selectValueNiceName(report, id, name, editable, issue) {
  return selectBase(report, id, name, v => ({ value: v.name, label: v.niceName }), editable, issue);
}

function selectManyBase(report, id, name, transform, alreadyFilter, editable, keepDefault) {
  let options = sv.SelectionValues[id].map(v => transform(v));
  let alreadySelected = [];
  if (report[id] != null) {
    if (alreadyFilter != null) {
      alreadySelected = alreadyFilter(options);
    } else {
      alreadySelected = options.filter(e => e != null).filter(e => report[id].includes(e.value));
    }
  }
  var placeholder = Placeholders[id];
  if (editable) {
    return (<ElementWrap>
      <label htmlFor={id}>{name}</label>
      <Select isMulti placeholder={placeholder} defaultValue={alreadySelected} options={options} onChange={e => {
        console.log(e);
        report[id] = e.map(v => v.value);
      }} hideSelectedOptions={false} />
    </ElementWrap>)
  }
  if (!keepDefault) {
    placeholder = "";
  }
  var state = alreadySelected.map(
    e => {
      if (typeof e.value === 'object' && e.value !== null) {
        return e.value.primary + ": " + e.value.secondary;
      }
      return e.label;
    }).join(", ");
  return nonEdit(id, name, alreadySelected == null || alreadySelected.length === 0 ? "" : state, placeholder);
}

function selectMany(report, id, name, editable) {
  return selectManyBase(report, id, name, v => ({ value: v, label: v }), null, editable);
}

function selectManyNiceName(report, id, name, editable, keepDefault) {
  return selectManyBase(report, id, name, v => ({ value: v.name, label: v.niceName }), null, editable, keepDefault);
}

function selectManyGrouped(report, id, name, editable) {
  return selectManyBase(report, id, name,
    v => ({
      label: v.primary, options: v.secondary.map(v2 =>
        ({ label: v2, value: { primary: v.primary, secondary: v2 } }))
    }), options => {
      if (report[id] != null) {
        var presSelected = report[id].filter(e => e != null);
        return options.flatMap(o => o.options).filter(v =>
          presSelected.findIndex(e => e.primary === v.value.primary && e.secondary === v.value.secondary) >= 0
        );
      } else {
        return [];
      }
    }, editable);
}

function errorPrint(issue, text) {
  var labelText = "Det här fältet är obligatoriskt!";
  if (text != null) {
    labelText = text;
  }
  if (issue) {
    return (<p style={{ backgroundColor: "#E9573F" }}>{labelText}</p>)
  } else {
    return (<></>);
  }
}

var exports = { stringValue, textValue, number, date, selectValue, selectValueNiceName, selectMany, selectManyNiceName, selectManyGrouped, errorPrint };

export default exports;
