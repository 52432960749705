import React from 'react';

import Navigation from './Navigation'
import API2 from './API';
import Colors from './constant/Colors';
import sv from './reports/SelectionValues';
import {Helmet} from "react-helmet";


import Report from './reports/ReportPage';

class MyReports extends React.Component {

  constructor(props) {
    super(props);
    this.state = { myReports: [] };
    this.logout = props.logout;
  }

  componentDidMount() {
    API2.myReports().then(d => {
      this.setState({ myReports: d });
    });
  }

  render() {
    var reps = this.state.myReports;
    return (
      <div>
        <Helmet>
          <title>Rapporter meny</title>
          <link rel="icon" href="/favicon.png" />
        </Helmet>
        <Navigation logout={this.logout} />
        <div className="container" style={{ backgroundColor: "#F3F3F3", borderRadius: "5px", padding: "25px" }}>
          <h2>Rapporter</h2>
          {ReportSection("Skapade", reps.filter(r => r.state === "created"), true)}
          {ReportSection("Inskickade", reps.filter(r => r.state === "atCi" || r.state === "atRi"), true)}
          {ReportSection("Historiska", reps.filter(r => r.state === "hidden" || r.state === "published"), true)}
        </div>
      </div>
    );
  }
}

function ReportSection(name, reports, clickable) {
  return (
    <div>
      <h3>{name}</h3>
      {reports.length == 0 ? <p>Inga rapporter</p> : reports.map(r => ReportSummary(r, clickable))}
    </div>

  );
}

function ReportSummary(r, clickable) {

  if (clickable) {
    return (
      <div key={r.token} style={{ color: "black" }}>
        <a href={"#/report/" + r.token} style={{ color: "black" }}>
          {summaryContent(r, true)}
        </a>
      </div>);
  } else {
    return (
      <div key={r.token} style={{ color: "black" }}>
        {summaryContent(r, false)}
      </div>)
  }
}

function summaryContent(r, clickable) {
  return (<div className="listedReport" style={{
    backgroundColor: Colors[r.type].color, borderStyle: "solid",
    borderRadius: "5px",
    margin: "15px",
    padding: "15px"
  }}>
    {Report.State(r)}
    <div >
      <h2>{r.activity}</h2>
      <div className="form-group">
        <label htmlFor="jumperLicensenumber">Datum för händelsen: {r.eventDate}</label>
      </div>
      <div className="form-group">
        <label htmlFor="jumperLicensenumber">Händelse: {r.eventDescription}</label>
      </div>

      <div className="form-row">
        <div className="col">
          <label htmlFor="club">Klubbtillhörighet: {sv.SelectionFancy("club", r.club)?.niceName ?? "?"}</label>
        </div>
        <div className="col">
          <ul>
            {r.injury.map(i => <li key={i.primary + i.secondary}><label htmlFor="injury">Skada: {i.primary} {i.secondary}</label></li>)}
          </ul>
        </div>
      </div>
    </div>
    {clickable ? <p className='text-center'>Klicka för att se!</p> : <p></p>}

  </div>);
}

export { MyReports, ReportSection };