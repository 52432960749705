import React from 'react';

import Navigation from './Navigation'

import API2 from './API';

import StackLineFunc from './chartTypes/StackLineFunc';
import PiChartFunc from './chartTypes/PiChartFunc';
import BarChartFunc from './chartTypes/BarChartFunc';

import Pl from './reports/ReportPlacing';

import ColorHash from 'color-hash';

import color from './constant/Colors';
import Select from 'react-select'


var colorHash = new ColorHash();

class Charts extends React.Component {
  constructor(props) {
    super(props);
    var year = new Date().getFullYear();
    this.state = { stats: null, year: year, years: [year] };
    this.logout = props.logout;
  }

  componentDidMount() {
    API2.stats(this.state.year).then(d => {
      this.setState({ stats: d });
    });
    API2.statYears().then(d => {
      this.setState({ years: d });
    });
  }

  newYear(year) {
    if (year === this.state.year) {
      console.log("Year unchanged");
    } else {
      console.log("Selected year became: ", year);
      API2.stats(year).then(d => {
        console.log("got result for: ", year);
        this.setState({ stats: d, year: year });
      });

    }
  }

  render() {
    var stats = this.state.stats;

    if (stats != null) {
      var cumTotal = { red: 0, blue: 0, yellow: 0 };

      var diagrams = [{
        n: "Fördelning",
        d: PiChartFunc(
          Object.entries(stats.reportCount).map((k, e) => ({
            name:
              color[(k[0])].displayName, value: k[1], color: color[(k[0])].strongColor
          })))
      },
      /*{
        n: "Över Rapporter över tid",
        d: LineChartFunc(
          stats.perMonth.map((d, i) => ({ name: i + 1, red: d.red, blue: d.blue }))
        )
      },
      */
      {
        n: "Rapporter över tid",
        d: BarChartFunc(
          stats.perMonth.map((d, i) => ({ name: i + 1, red: d.red, blue: d.blue, yellow: d.yellow }))
        )
      },
      {
        n: "Totala rapporter över tid",
        d: StackLineFunc(
          stats.perMonth.map((d, i) => {
            cumTotal.blue += d.blue;
            cumTotal.yellow += d.yellow;
            cumTotal.red += d.red;
            return ({ name: i + 1, red: cumTotal.red, blue: cumTotal.blue, yellow: cumTotal.yellow });
          })
        )
      },
      {
        n: "Fördelning av skador",
        d: PiChartFunc(
          Object.entries(stats.injuryLocationCounts).map((k, e) => ({ name: k[0], value: k[1], color: colorHash.hex(k[0]) })))
      }
      ]
      var options = this.state.years.map(v => ({ value: v, label: v }));
      return (
        <div>
          <Navigation logout={this.logout} />
          <div className={"container"} style={{ backgroundColor: '#F5F5F5', borderRadius: '5px', padding: '25px' }}>
            <Pl.FormGroup title={"År"}>
              <Select defaultValue={options[0]} onChange={(e) => this.newYear(e.value)} options={options} />
            </Pl.FormGroup>

            {diagrams.map((d, i) =>
            (<Pl.FormGroup key={i} title={d.n}>
              {d.d}
            </Pl.FormGroup>)
            )}
          </div>
        </div>
      );
    } else {
      <div>
        <Navigation logout={this.logout} />
        <p>Loading data</p>
      </div>
    }
  }
}

export default Charts;