import API2 from '../API';
import Pl from './ReportPlacing';
import El from './ReportElements';

function Buttons(r, errorMessage, stateBack, jwtBody, issue) {
    var role = jwtBody.role;

    var editable = (r.state === "atRi" && role === "RI")
        || (r.state === "atCi" && role === "CI" && r.club === jwtBody.ciClub)
        || (r.state === "created" && r.createdBy === jwtBody.sub);
    //|| ;

    var buttons = [];

    if (editable) {

        if (r.token == null) {
            buttons.push(ActionButton("btn-success", "Spara!", () => postSave(r, stateBack)));
        } else {
            buttons.push(ActionButton("btn-success", "Spara!", () => putSave(r, stateBack)));
        }

        if (r.token == null) {
            buttons.push(ActionButton("btn-primary", "Skicka in!", () => postCi(r, stateBack), issue));
        } else if (r.state === "created") {
            buttons.push(ActionButton("btn-danger", "Ta bort!", () => deleteReport(r, stateBack)));
            buttons.push(ActionButton("btn-primary", "Skicka in!", () => putState(r, stateBack, "atCi")));
        }

        if (r.state === "atCi") {
            buttons.push(ActionButton("btn-light", "Skicka in till RI!", () => putState(r, stateBack, "atRi")));
        }
        if (r.state === "atRi") {
            buttons.push(ActionButton("btn-light", "Publicera!", () => putState(r, stateBack, "published")));
            buttons.push(ActionButton("btn-light", "Dölj!", () => putState(r, stateBack, "hidden")));
        }
    }

    if (errorMessage == null) {
        return (
            <Pl.FormRow extraClass={"text-center"}>
                {buttons}
            </Pl.FormRow>);
    } else {
        return (
            <div>
                <p className="text-center">Felmeddelande från servern: {errorMessage}</p>
                <Pl.FormRow extraClass={"text-center"}>
                    {buttons}
                </Pl.FormRow>
            </div>
        );
    }

}
let requiredFields = ["club", "eventDate", "eventDescription"];

function verify(report) {
    var keys = Object.keys(report);
    var result = requiredFields.filter(f => !keys.includes(f) || report[f] == null || report[f] === "");

    console.log("result of verify: ", result);
    return result;
}

function ActionButton(buttonType, buttonText, buttonAction, issue) {
    return (
    <div key={buttonText}>
        {El.errorPrint(issue, "Fyll i alla obligatoriska fält")}
        <button type="button" key={buttonText} className={"btn waves-effect waves-light btn-lg " + buttonType} id="btn-hide" onClick={() => buttonAction()}>{buttonText}</button>
        </div>
      );

}

function postSave(report, stateBack) {
    API2.createReport(report).then(r => {
        console.log("Going to ", r.token);
        window.location.href = "#/report/" + r.token;
    }).catch(errorCallback(stateBack));
}

function putSave(report, stateBack) {
    API2.updateReport(report)
        .then(successFunction(stateBack))
        .catch(errorCallback(stateBack));
}

function deleteReport(report, stateBack) {
    API2.deleteReport(report).then(r => {
        window.location.href = "#/myReports";
    })
        .catch(errorCallback(stateBack));
}

function postCi(report, stateBack) {
    var issue = verify(report);
    if (issue.length === 0) {
        API2.createReportToCi(report).then(r => {
            window.location.href = "#/report/" + r.token;
        }).catch(errorCallback(stateBack));
    } else {
        stateBack({ issues: issue });
    }
}

function putState(report, stateBack, newState) {
    var issue = verify(report);
    if (issue.length === 0) {
        API2.updateReport(report, "/" + newState)
            .then(successFunction(stateBack))
            .catch(errorCallback(stateBack));
    } else {
        stateBack({ issues: issue });
    }
}


function successFunction(stateBack) {
    return (r) => {
        stateBack({ report: r, errorMessage: null });
    }

}

function errorCallback(stateBack) {
    return (e) => {
        e.then(m => {
            console.log("Got error: ", m);
            stateBack({ errorMessage: m.message });
        });
    }
}

export default Buttons;