var SelectionValues = {
  "state": [
    {
      "niceName": "Skapad",
      "name": "created"
    },
    {
      "niceName": "Hos CI",
      "name": "atCi"
    },
    {
      "niceName": "Hos RI",
      "name": "atRi"
    },
    {
      "niceName": "Dold av RI",
      "name": "hidden"
    },
    {
      "niceName": "Publicerad av RI",
      "name": "published"
    }
  ],
  "type": [
    {
      "name": "BLUE",
      "strongColor": "#add8e6",
      "displayName": "Blå",
      "color": "#e8f4f8"
    },
    {
      "name": "RED",
      "strongColor": "#E6BCAD",
      "displayName": "Röd",
      "color": "#f8ece8"
    },
    {
      "name": "YELLOW",
      "strongColor": "#fafa87",
      "displayName": "Reservdrag",
      "color": "#fdfdd0"
    }
  ],
  "jumperLicense": [
    "E",
    "A",
    "B",
    "C",
    "D"
  ],
  "jumperCompetences": [
    "HL",
    "HM",
    "KL",
    "KI",
    "AFF",
    "Tandem"
  ],
  "jumperClub": [
    {
      "place": "Umeå",
      "niceName": "Umeå Fallskärmsklubb",
      "name": "umea"
    },
    {
      "place": "Optands flygfält",
      "niceName": "Östersunds Fallskärmsklubb, Östersund",
      "name": "ostersund"
    },
    {
      "place": "Sundsvall, Bänkås",
      "niceName": "Sundsvalls Fallskärmsklubb",
      "name": "sundsvall"
    },
    {
      "place": "Söderhamn, Mohed",
      "niceName": "Söderhamns Fallskärmsklubb",
      "name": "soderhamn"
    },
    {
      "place": "Borlänge, Romme",
      "niceName": "Fallskärmklubben Dala",
      "name": "dala"
    },
    {
      "place": "Gävle (inget hoppfält)",
      "niceName": "Fallskärm Gefle CReW Club",
      "name": "gefle"
    },
    {
      "place": "Tierp, Gryttjom",
      "niceName": "Stockholms Fallskärmsklubb",
      "name": "stockholm"
    },
    {
      "place": "Västerås, Johannisberg",
      "niceName": "Fallskärmsklubben Aros",
      "name": "aros"
    },
    {
      "place": "Örebro flygplats",
      "niceName": "Örebro Fallskärmsklubb",
      "name": "orebro"
    },
    {
      "place": "Skavsta flygplats",
      "niceName": "Nyköpings Fallskärmsklubb",
      "name": "nykoping"
    },
    {
      "place": "Motala, Skärstad",
      "niceName": "Linköpings Fallskärmsklubb",
      "name": "linkoping"
    },
    {
      "place": "Visby",
      "niceName": "Gotlands Fallskärmsklubb",
      "name": "gotland"
    },
    {
      "place": "Vårgårda",
      "niceName": "Fallskärmsklubben Cirrus Göteborg",
      "name": "fkcg"
    },
    {
      "place": "Vittaryd, Feringe flygplats",
      "niceName": "Smålands Fallskärmsklubb",
      "name": "smalands"
    },
    {
      "place": "Halmstad",
      "niceName": "Halmstads Fallskärmsklubb",
      "name": "halmstad"
    },
    {
      "place": "Kristianstad, Everöd",
      "niceName": "Skånes Fallskärmsklubb",
      "name": "skane"
    },
    {
      "place": "Eslöv",
      "niceName": "Fallskärmsklubben Syd",
      "name": "syd"
    }
  ],
  "club": [
    {
      "place": "Umeå",
      "niceName": "Umeå Fallskärmsklubb",
      "name": "umea"
    },
    {
      "place": "Optands flygfält",
      "niceName": "Östersunds Fallskärmsklubb, Östersund",
      "name": "ostersund"
    },
    {
      "place": "Sundsvall, Bänkås",
      "niceName": "Sundsvalls Fallskärmsklubb",
      "name": "sundsvall"
    },
    {
      "place": "Söderhamn, Mohed",
      "niceName": "Söderhamns Fallskärmsklubb",
      "name": "soderhamn"
    },
    {
      "place": "Borlänge, Romme",
      "niceName": "Fallskärmklubben Dala",
      "name": "dala"
    },
    {
      "place": "Gävle (inget hoppfält)",
      "niceName": "Fallskärm Gefle CReW Club",
      "name": "gefle"
    },
    {
      "place": "Tierp, Gryttjom",
      "niceName": "Stockholms Fallskärmsklubb",
      "name": "stockholm"
    },
    {
      "place": "Västerås, Johannisberg",
      "niceName": "Fallskärmsklubben Aros",
      "name": "aros"
    },
    {
      "place": "Örebro flygplats",
      "niceName": "Örebro Fallskärmsklubb",
      "name": "orebro"
    },
    {
      "place": "Skavsta flygplats",
      "niceName": "Nyköpings Fallskärmsklubb",
      "name": "nykoping"
    },
    {
      "place": "Motala, Skärstad",
      "niceName": "Linköpings Fallskärmsklubb",
      "name": "linkoping"
    },
    {
      "place": "Visby",
      "niceName": "Gotlands Fallskärmsklubb",
      "name": "gotland"
    },
    {
      "place": "Vårgårda",
      "niceName": "Fallskärmsklubben Cirrus Göteborg",
      "name": "fkcg"
    },
    {
      "place": "Vittaryd, Feringe flygplats",
      "niceName": "Smålands Fallskärmsklubb",
      "name": "smalands"
    },
    {
      "place": "Halmstad",
      "niceName": "Halmstads Fallskärmsklubb",
      "name": "halmstad"
    },
    {
      "place": "Kristianstad, Everöd",
      "niceName": "Skånes Fallskärmsklubb",
      "name": "skane"
    },
    {
      "place": "Eslöv",
      "niceName": "Fallskärmsklubben Syd",
      "name": "syd"
    }
  ],
  "rsl": [
    {
      "niceName": "Ja",
      "name": true
    },
    {
      "niceName": "Nej",
      "name": false
    }
  ],
  "jumptype": [
    {
      "niceName": "Aut. utdragsrem",
      "name": "automatic"
    },
    {
      "niceName": "JAD",
      "name": "jad"
    },
    {
      "niceName": "Manuell",
      "name": "manuel"
    },
    {
      "niceName": "AFF",
      "name": "aff"
    },
    {
      "niceName": "Tandem",
      "name": "tandem"
    },
    {
      "niceName": "Uppvisning",
      "name": "excibition"
    },
    {
      "niceName": "Tävling",
      "name": "competetion"
    },
    {
      "niceName": "FS mage",
      "name": "fs"
    },
    {
      "niceName": "Friflygning",
      "name": "ff"
    },
    {
      "niceName": "Wingsuit",
      "name": "wingsuit"
    },
    {
      "niceName": "Vinkel/track",
      "name": "track"
    },
    {
      "niceName": "Speed",
      "name": "speed"
    },
    {
      "niceName": "CP",
      "name": "cp"
    },
    {
      "niceName": "CF",
      "name": "cf"
    }
  ],
  "malfunction": [
    {
      "primary": "Flygning",
      "secondary": [
        "Nödhopp",
        "Vådautlösning"
      ]
    },
    {
      "primary": "Uthopp",
      "secondary": [
        "Ostabilt",
        "Vådautlösning"
      ]
    },
    {
      "primary": "Frifall",
      "secondary": [
        "Flatspinn",
        "Ostabilt",
        "Kollision",
        "Vådautlösning"
      ]
    },
    {
      "primary": "Drag/Handtag",
      "secondary": [
        "Ostabilt",
        "Fastnat",
        "Lossnat",
        "Ej funnit",
        "Tappat",
        "Lågt",
        "Ej orkat"
      ]
    },
    {
      "primary": "Utveckling",
      "secondary": [
        "Långsam",
        "Ofrivillig",
        "Utebliven",
        "Låg höjd"
      ]
    },
    {
      "primary": "Fallskärm",
      "secondary": [
        "Slider/reefing",
        "Delad",
        "Vrängd",
        "Trasig",
        "Inblåsning"
      ]
    },
    {
      "primary": "Linor",
      "secondary": [
        "Tensionknot",
        "Styrlinebrott",
        "Snodda",
        "Trasslade",
        "Linbrott",
        "Runt kroppen"
      ]
    },
    {
      "primary": "Övrigt",
      "secondary": [
        "Losskoppling",
        "Chockbroms",
        "Hjälpfallskärm",
        "Räddningsutlösare"
      ]
    }
  ],
  "injury": [
    {
      "primary": "Huvud",
      "secondary": [
        "Slag",
        "Sår",
        "Hjärnskakning",
        "Fraktur",
        "Blödning",
        "Svimning",
        "Medvetslös"
      ]
    },
    {
      "primary": "Hals/Nacke",
      "secondary": [
        "Sträckning",
        "Sår",
        "Slag",
        "Fraktur",
        "Spricka",
        "Whiplash"
      ]
    },
    {
      "primary": "Axel/Arm/Hand",
      "secondary": [
        "Stukning",
        "Vrickning",
        "Fraktur",
        "Spricka",
        "Ur led"
      ]
    },
    {
      "primary": "Rygg",
      "secondary": [
        "Stukning",
        "Vrickning",
        "Sträckning",
        "Fraktur",
        "Spricka"
      ]
    },
    {
      "primary": "Höft/Bäcken",
      "secondary": [
        "Stukning",
        "Vrickning",
        "Sträckning",
        "Fraktur",
        "Spricka"
      ]
    },
    {
      "primary": "Lårben",
      "secondary": [
        "Utgjutning",
        "Sträckning",
        "Fraktur",
        "Spricka"
      ]
    },
    {
      "primary": "Underben",
      "secondary": [
        "Utgjutning",
        "Sträckning",
        "Fraktur",
        "Spricka"
      ]
    },
    {
      "primary": "Fot",
      "secondary": [
        "Stukning",
        "Vrickning",
        "Sträckning",
        "Fraktur",
        "Spricka",
        "Ur led"
      ]
    }
  ],
  "propertyDamage": [
    {
      "niceName": "Ja",
      "name": true
    },
    {
      "niceName": "Nej",
      "name": false
    }
  ],
  "landingPlace": [
    {
      "niceName": "Ledning",
      "name": "wire"
    },
    {
      "niceName": "Hinder",
      "name": "obstacle"
    },
    {
      "niceName": "Vatten",
      "name": "water"
    },
    {
      "niceName": "Kalhygge",
      "name": "clearing"
    },
    {
      "niceName": "Skog",
      "name": "forest"
    },
    {
      "niceName": "Hård mark",
      "name": "hardGround"
    },
    {
      "niceName": "Ojämn mark",
      "name": "unevenGround"
    },
    {
      "niceName": "Bil",
      "name": "car"
    },
    {
      "niceName": "Stad",
      "name": "city"
    },
    {
      "niceName": "Flygplan",
      "name": "airplane"
    },
    {
      "niceName": "Byggnad",
      "name": "building"
    }
  ],
  "landingStyle": [
    {
      "niceName": "Låg vinglast",
      "name": "lowWingload"
    },
    {
      "niceName": "Hög vinglast",
      "name": "highWingload"
    },
    {
      "niceName": "Ovana",
      "name": "notCurrent"
    }
  ],
  "canopyStyle": [
    {
      "niceName": "Medvind",
      "name": "downWind"
    },
    {
      "niceName": "Sidvind",
      "name": "sideWind"
    },
    {
      "niceName": "Ojämn flär",
      "name": "unevenFlair"
    },
    {
      "niceName": "Ingen flär",
      "name": "noFlare"
    },
    {
      "niceName": "Stall",
      "name": "stall"
    },
    {
      "niceName": "Låg flär",
      "name": "lowFlare"
    },
    {
      "niceName": "Hög flär",
      "name": "highFlare"
    },
    {
      "niceName": "Kollision",
      "name": "collission"
    },
    {
      "niceName": "Pendling",
      "name": "pendulem"
    },
    {
      "niceName": "Hookturn",
      "name": "hookTurn"
    },
    {
      "niceName": "Låg sväng",
      "name": "lowTurn"
    }
  ],
  "clouds": [
    {
      "niceName": "Lätta låga moln",
      "name": "lightLow"
    },
    {
      "niceName": "Lätta höga moln",
      "name": "lightHigh"
    },
    {
      "niceName": "Täta låga moln",
      "name": "heavyLow"
    },
    {
      "niceName": "Täta höga moln",
      "name": "heavyHigh"
    }
  ],
  "weather": [
    {
      "niceName": "Termik",
      "name": "thermals"
    },
    {
      "niceName": "Torrt",
      "name": "dry"
    },
    {
      "niceName": "Fuktigt",
      "name": "damp"
    },
    {
      "niceName": "Vindskjuvning",
      "name": "windShed"
    },
    {
      "niceName": "Byigt",
      "name": "gusty"
    },
    {
      "niceName": "Kyttigt",
      "name": "turbulent"
    }
  ]
}


const SelectionFancy = function(field, value){
    var result = SelectionValues[field].find(f => f.name === value);
    if(result != null){
        return result;
    }
    return null;
}

export default {SelectionValues, SelectionFancy};