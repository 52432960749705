
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import React from 'react';


function BarChartFunc(data) {
    return <BarChart
          width={1000}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          
          <Bar dataKey="red" stackId="a" fill="#E6BCAD" />
          <Bar dataKey="blue" stackId="a" fill="#add8e6" />
          <Bar dataKey="yellow" stackId="a" fill="#ececa3" />
        </BarChart>
}

export default BarChartFunc;